import { Commit, Dispatch } from 'vuex';
import { Message } from '@/types/entities';
import { Paginated, SearchMessagesParams } from '@/types/search';
import { pickBy } from 'lodash';
import axios from 'axios';

export const FETCH_MESSAGES = 'messages/FETCH_MESSAGES';
export const DELETE_MESSAGES_OF_USER = 'messages/DELETE_MESSAGES_OF_USER';
export const DELETE_MESSAGE = 'messages/DELETE_MESSAGE';

export const SET_MESSAGES = 'messages/SET_MESSAGES';
export const RESET_STATE = 'messages/RESET_STATE';

export const GET_MESSAGES = 'messages/GET_MESSAGES';

declare interface MessagesState {
  messagesList: Paginated<Message>;
}

const initialState: MessagesState = {
  messagesList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_MESSAGES](state: MessagesState) {
      return state.messagesList;
    }
  },

  mutations: {
    [SET_MESSAGES](state: MessagesState, messages: Paginated<Message>) {
      state.messagesList = messages;
    },
    [RESET_STATE](state: MessagesState) {
      state.messagesList = {
        total: 0,
        data: [],
        current_page: 1
      };
    }
  },

  actions: {
    [FETCH_MESSAGES]({ commit }: { commit: Commit }, params: SearchMessagesParams) {
      return axios.get('/messages', { params: pickBy(params) })
        .then((response) => commit(SET_MESSAGES, response.data));
    },
    [DELETE_MESSAGES_OF_USER]({}, userId: number ) {
      return axios.delete(`/users/${userId}/messages`);
    },
    [DELETE_MESSAGE]({}, messageId: number) {
      return axios.delete(`/messages/${messageId}`);
    }
  }
};
