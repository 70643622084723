import Vue from 'vue';
import { Commit } from 'vuex';
import { Setting } from '@/types/entities';
import { Paginated, SearchSettingsParams } from '@/types/search';
import { pickBy, findIndex } from 'lodash';
import axios from 'axios';

export const FETCH_SETTINGS = 'settings/FETCH_SETTINGS';
export const UPDATE_SETTING = 'settings/UPDATE_SETTING';

export const RESET_STATE = 'settings/RESET_STATE';
export const SET_SETTINGS = 'settings/SET_SETTINGS';
export const SET_SETTING_VALUE = 'settings/SET_SETTING_VALUE';
export const SET_DEFAULT_SPORTS = 'setting/SET_DEFAULT_SPORTS';

export const GET_SETTINGS = 'settings/GET_SETTING';

declare interface SettingState {
  itemsList: Paginated<Setting>;
}

const initialState: SettingState = {
  itemsList: {
    total: 0,
    data: [],
    current_page: 1
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_SETTINGS](state: SettingState) {
      return state.itemsList;
    }
  },

  mutations: {
    [SET_SETTINGS](state: SettingState, settings: Paginated<Setting>) {
      state.itemsList = settings;
    },
    [SET_SETTING_VALUE](state: SettingState, data: { setting: Setting, key: string, value: any }) {
      const itemIndex = findIndex(state.itemsList.data, { name: data.setting.name });

      Vue.set(state.itemsList.data[itemIndex].value, data.key, data.value);
    },
    [SET_DEFAULT_SPORTS](state: SettingState, data: Array<string>) {
      Vue.set(state.itemsList.data[0], 'value', data);
    },
    [RESET_STATE](state: SettingState) {
      state.itemsList = {
        total: 0,
        data: [],
        current_page: 1
      };
    }
  },

  actions: {
    [FETCH_SETTINGS]({ commit }: { commit: Commit }, params: SearchSettingsParams) {
      return axios.get('/settings', { params: pickBy(params) })
        .then((response) => commit(SET_SETTINGS, response.data));
    },
    [UPDATE_SETTING]({ commit, state }: { commit: Commit, state: SettingState }, setting: Setting) {
      return axios.put(`/settings/${setting.name}`, setting.value);
    }
  }
};
