import { Commit } from 'vuex';
import { PickEntity } from '@/types/entities';
import { Paginated, SearchPicksParams } from '@/types/search';
import { pickBy } from 'lodash';
import axios from 'axios';

export const SEARCH_PICKS = 'picks/SEARCH_PICKS';

export const RESET_STATE = 'picks/RESET_STATE';
export const SET_PICKS = 'picks/SET_PICKS';

declare interface PicksState {
  picksList: Paginated<PickEntity>;
}

const initialState: PicksState = {
  picksList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 10
  }
};

export default {
  state: { ...initialState },

  mutations: {
    [RESET_STATE](state: PicksState) {
      state.picksList = {
        total: 0,
        data: [],
        current_page: 1,
        per_page: 10
      };
    },
    [SET_PICKS](state: PicksState, data: Paginated<PickEntity>) {
      state.picksList = data;
    }
  },

  actions: {
    [SEARCH_PICKS]({ commit, state }: { commit: Commit, state: PicksState }, params: SearchPicksParams) {
      return axios.get(`/picks`, { params: pickBy(params) })
          .then((response) => commit(SET_PICKS, response.data));
    }
  }
};
