import { Commit } from 'vuex';
import { Sport, SportNotStrict } from '@/types/entities';
import { Paginated, SearchSportsParams } from '@/types/search';
import { pickBy } from 'lodash';
import axios from 'axios';

export const FETCH_SPORTS = 'sports/FETCH_SPORTS';
export const UPDATE_SPORTS = 'sports/UPDATE_SPORTS';

export const RESET_STATE = 'sports/RESET_STATE';
export const SET_SPORTS = 'sports/SET_SPORTS';
export const SET_SPORT_DATA = 'sports/SET_SPORT_DATA';

export const GET_SPORTS = 'sports/GET_SPORT';

declare interface SportState {
  sportsList: Paginated<Sport>;
}

const initialState: SportState = {
  sportsList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_SPORTS](state: SportState) {
      return state.sportsList;
    }
  },

  mutations: {
    [SET_SPORTS](state: SportState, sports: Paginated<Sport>) {
      state.sportsList = sports;
    },
    [RESET_STATE](state: SportState) {
      state.sportsList = {
        total: 0,
        data: [],
        current_page: 1
      };
    },
    [SET_SPORT_DATA](state: SportState, data: { id: number, sport: SportNotStrict }) {
      state.sportsList.data = state.sportsList.data
        .map((sport) => (sport.id === data.id) ? Object.assign(sport, data.sport) : sport);
    }
  },

  actions: {
    [FETCH_SPORTS]({ commit }: { commit: Commit }, params: SearchSportsParams) {
      return axios.get('/sports', { params: pickBy(params) })
        .then((response) => commit(SET_SPORTS, response.data));
    },
    [UPDATE_SPORTS]({ commit }: { commit: Commit }, { id, params }: { id: number, params: Sport }) {
      return axios.put(`/sports/${id}`, params)
        .then(() => commit(SET_SPORT_DATA, { id, sport: params }));
    }
  }
};
