import { BotCreationData, NotStrictUser, Trophy, User } from '@/types/entities';
import { roles } from '@/types/enums';
import { Paginated, SearchUsersParams } from '@/types/search';
import axios from 'axios';
import { clone, pickBy } from 'lodash';
import { Commit } from 'vuex';

export const FETCH_USER = 'users/FETCH_USER';
export const SEARCH_USERS = 'users/SEARCH_USERS';
export const UPDATE_USER = 'users/UPDATE_USER';
export const BLOCK_USER = 'users/BLOCK_USER';
export const TURN_OFF_BOT = 'users/TURN_OFF_BOT';
export const TURN_ON_BOT = 'users/TURN_ON_BOT';
export const RESTORE_USER = 'users/RESTORE_USER';
export const CREATE_BOTS = 'users/CREATE_BOTS';
export const CREATE_TROPHY = 'users/CREATE_TROPHY';
export const UPDATE_TROPHY = 'users/UPDATE_TROPHY';
export const DELETE_TROPHY = 'users/DELETE_TROPHY';

export const RESET_STATE = 'users/RESET_STATE';
export const SET_USERS = 'users/SET_USERS';
export const SET_USER_ITEM = 'users/SET_USER_ITEM';
export const SET_USER_ITEM_VALUE = 'users/SET_USER_ITEM_VALUE';

export const GET_USERS = 'users/GET_USER';

declare interface UserState {
  usersList: Paginated<User>;
  item: User;
}

const initialState: UserState = {
  usersList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  },
  item: {
    name: '',
    email: '',
    role_id: roles.user,
    profile: {},
    profit: 0,
    rank: 0,
    is_confirmed: false,
    image_id: null,
    balance: 0,
    image: null,
    referrals: [],
    referral_code: '',
    trophies: []
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_USERS](state: UserState) {
      return state.usersList;
    }
  },

  mutations: {
    [SET_USERS](state: UserState, users: Paginated<User>) {
      state.usersList = users;
    },
    [SET_USER_ITEM](state: UserState, data: User) {
      state.item = data;
    },
    [SET_USER_ITEM_VALUE](state: UserState, data: NotStrictUser) {
      Object.assign(state.item, data);
    },
    [RESET_STATE](state: UserState) {
      state.usersList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        name: '',
        email: '',
        role_id: 2, // regular user
        profile: {},
        profit: 0,
        rank: 0,
        is_confirmed: false,
        image_id: null,
        balance: 0,
        image: null,
        referral_code: '',
        referrals: [],
        trophies: []
      };
    }
  },

  actions: {
    [FETCH_USER]({ commit }: { commit: Commit }, id: number) {
      const params = {
        with_trashed: 1,
        with: ['referrals', 'trophies']
      };

      return axios.get(`/users/${id}`, { params })
        .then((response) => commit(SET_USER_ITEM, response.data));
    },

    [UPDATE_USER]({ commit, state }: { commit: Commit, state: UserState }) {
      const data: User = clone(state.item);

      return axios.put(`/users/${state.item.id}`, data);
    },

    [BLOCK_USER]({ commit, state }: { commit: Commit, state: UserState }) {
      return axios.delete(`/users/${state.item.id}`);
    },

    [TURN_OFF_BOT]({ commit, state }: { commit: Commit, state: UserState }) {
      return axios.put(`/users/${state.item.id}`, {
        profile: {
          is_active: false
        }
      });
    },

    [TURN_ON_BOT]({ commit, state }: { commit: Commit, state: UserState }) {
      return axios.put(`/users/${state.item.id}`, {
        profile: {
          is_active: true
        }
      });
    },

    [CREATE_TROPHY]({ commit }: { commit: Commit }, trophy: Trophy) {
      return axios.post(`/trophies`, pickBy(trophy));
    },

    [UPDATE_TROPHY]({ commit }: { commit: Commit }, trophy: Trophy) {
      return axios.put(`/trophies/${trophy.id}`, pickBy(trophy));
    },

    [DELETE_TROPHY]({ commit }: { commit: Commit }, trophyID: number) {
      return axios.delete(`/trophies/${trophyID}`);
    },

    [RESTORE_USER]({ commit, state }: { commit: Commit, state: UserState }) {
      return axios.post(`/users/${state.item.id}/restore`);
    },

    [SEARCH_USERS]({ commit, state }: { commit: Commit, state: UserState }, params: SearchUsersParams) {
      return axios.get(`/users`, { params: pickBy(params) })
          .then((response) => commit(SET_USERS, response.data));
    },

    [CREATE_BOTS]({ commit, state }: { commit: Commit, state: UserState }, params: BotCreationData) {
      return axios.post(`/bots`, params);
    }
  }
};
