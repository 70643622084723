import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {auth: false},
    },
    {
      path: '/',
      name: 'app',
      component: () => import('./views/MainWrapper.vue'),
      meta: {auth: true},
      children: [
        {
          path: 'insights',
          name: 'app.insights.list',
          component: () => import('./views/InsightsList.vue'),
          meta: { title: 'Insights', requiresAuth: true }
        },
        {
          path: 'insights/new',
          name: 'app.insights.new',
          component: () => import('./views/InsightsCreate.vue'),
          meta: { title: 'Create new insight', requiresAuth: true }
        },
        {
          path: 'insights/:slug',
          name: 'app.insights.details',
          component: () => import('./views/InsightsDetails.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'bookmakers',
          name: 'app.bookmakers.list',
          component: () => import('./views/BookmakersList.vue'),
          meta: { title: 'Bookmakers', requiresAuth: true }
        },
        {
          path: 'bookmakers/new',
          name: 'app.bookmakers.new',
          component: () => import('./views/BookmakersCreate.vue'),
          meta: { title: 'Create new bookmaker', requiresAuth: true }
        },
        {
          path: 'bookmakers/:id',
          name: 'app.bookmakers.details',
          component: () => import('./views/BookmakersDetails.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'bookmaker-promotions',
          name: 'app.bookmakerPromotions.list',
          component: () => import('./views/BookmakerPromotionsList.vue'),
          meta: { title: 'Bookmaker Promotions', requiresAuth: true }
        },
        {
          path: 'bookmaker-promotions/new',
          name: 'app.bookmakerPromotions.new',
          component: () => import('./views/BookmakerPromotionCreate.vue'),
          meta: { title: 'Create new Bookmaker Promotion', requiresAuth: true }
        },
        {
          path: 'bookmaker-promotions/:id',
          name: 'app.bookmakerPromotions.details',
          component: () => import('./views/BookmakerPromotionDetails.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'competitions',
          name: 'app.competitions.list',
          component: () => import('./views/CompetitionsList.vue'),
          meta: { title: 'Competitions', requiresAuth: true }
        },
        {
          path: 'competitions/new',
          name: 'app.competitions.new',
          component: () => import('./views/CompetitionsCreate.vue'),
          meta: { title: 'Competitions', requiresAuth: true }
        },
        {
          path: 'competitions/:id',
          name: 'app.competitions.details',
          component: () => import('./views/CompetitionsDetails.vue'),
          meta: { title: 'Competitions', requiresAuth: true }
        },
        {
          path: 'transactions',
          name: 'app.transactions.list',
          component: () => import('./views/TransactionsList.vue'),
          meta: { title: 'Transactions', requiresAuth: true }
        },
        {
          path: 'transactions/:id',
          name: 'app.transactions.details',
          component: () => import('./views/TransactionsDetails.vue'),
          meta: { title: 'Transactions', requiresAuth: true }
        },
        {
          path: 'banner',
          name: 'app.banner',
          component: () => import('./views/LeaderboardBanner.vue'),
          meta: { title: 'Leaderboard Banner', requiresAuth: true }
        },
        {
          path: 'documentation',
          name: 'app.documentation',
          component: () => import('./views/Documentation.vue'),
          meta: { title: 'Documentation', requiresAuth: true }
        },
        {
          path: 'users',
          name: 'app.users.list',
          component: () => import('./views/UsersList.vue'),
          meta: { title: 'Users', requiresAuth: true }
        },
        {
          path: 'referral-codes',
          name: 'app.referralCodes.list',
          component: () => import('./views/ReferralCodesList.vue'),
          meta: { title: 'Referral Codes', requiresAuth: true }
        },
        {
          path: 'referral-codes/new',
          name: 'app.referralCodes.new',
          component: () => import('./views/ReferralCodeCreate.vue'),
          meta: { title: 'Create new referral code', requiresAuth: true }
        },
        {
          path: 'referral-codes/:id',
          name: 'app.referralCodes.edit',
          component: () => import('./views/ReferralCodeCreate.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'bots',
          name: 'app.bots.list',
          component: () => import('./views/BotsList.vue'),
          meta: { title: 'Bots', requiresAuth: true }
        },
        {
          path: 'users/:id',
          name: 'app.users.details',
          component: () => import('./views/UsersDetails.vue'),
          meta: { title: 'Users', requiresAuth: true }
        },
        {
          path: 'settings',
          name: 'app.settings.list',
          component: () => import('./views/SettingsList.vue'),
          meta: { title: 'Settings', requiresAuth: true }
        },
        {
          path: 'sports',
          name: 'app.sports.list',
          component: () => import('./views/SportsList.vue'),
          meta: { title: 'Sports', requiresAuth: true }
        },
        {
          path: 'leagues',
          name: 'app.leagues.list',
          component: () => import('./views/LeaguesList.vue'),
          meta: { title: 'Leagues', requiresAuth: true }
        }
      ]
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    return next({ name: 'app.insights.list' });
  }

  if (to.meta?.title) {
    store.commit('setTitle', to.meta?.title);
  }

  next();
});

export default router;
