import { Commit } from 'vuex';
import { League } from '@/types/entities';
import { Paginated, SearchLeaguesParams } from '@/types/search';
import { pickBy } from 'lodash';
import axios from 'axios';

export const FETCH_LEAGUES = 'leagues/FETCH_LEAGUES';
export const UPDATE_LEAGUES = 'leagues/UPDATE_LEAGUES';

export const RESET_STATE = 'leagues/RESET_STATE';
export const SET_LEAGUES = 'leagues/SET_LEAGUES';
export const SET_LEAGUE_DATA = 'leagues/SET_LEAGUE_DATA';

export const GET_LEAGUES = 'leagues/GET_LEAGUE';

declare interface LeagueState {
  leaguesList: Paginated<League>;
}

const initialState: LeagueState = {
  leaguesList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_LEAGUES](state: LeagueState) {
      return state.leaguesList;
    }
  },

  mutations: {
    [SET_LEAGUES](state: LeagueState, leagues: Paginated<League>) {
      state.leaguesList = leagues;
    },
    [RESET_STATE](state: LeagueState) {
      state.leaguesList = {
        total: 0,
        data: [],
        current_page: 1
      };
    },
    [SET_LEAGUE_DATA](state: LeagueState, data: { id: number, league: League }) {
      state.leaguesList.data = state.leaguesList.data
        .map((league) => (league.id === data.id) ? Object.assign(league, data.league) : league);
    }
  },

  actions: {
    [FETCH_LEAGUES]({ commit }: { commit: Commit }, params: SearchLeaguesParams) {
      return axios.get('/leagues', { params: pickBy(params) })
        .then((response) => commit(SET_LEAGUES, response.data));
    },
    [UPDATE_LEAGUES]({ commit }: { commit: Commit }, { id, params }: { id: number, params: League }) {
      return axios.put(`/leagues/${id}`, params)
        .then(() => commit(SET_LEAGUE_DATA, { id, league: params }));
    }
  }
};
