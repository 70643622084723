import { Transaction } from '@/types/entities';
import { Paginated, SearchTransactionsParams } from '@/types/search';
import axios from 'axios';
import { findIndex, pickBy } from 'lodash';
import Vue from 'vue';
import { Commit } from 'vuex';

export const SEARCH_TRANSACTIONS = 'transactions/SEARCH_TRANSACTIONS';
export const FETCH_TRANSACTION = 'transactions/FETCH_TRANSACTION';
export const CONFIRM_TRANSACTION = 'transactions/CONFIRM_TRANSACTION';
export const DECLINE_TRANSACTION = 'transactions/DECLINE_TRANSACTION';

export const RESET_STATE = 'transactions/RESET_STATE';
export const SET_TRANSACTIONS = 'transactions/SET_TRANSACTIONS';
export const SET_TRANSACTION_ITEM = 'transactions/SET_TRANSACTION_ITEM';
export const SET_TRANSACTION_VALUE = 'transactions/SET_TRANSACTION_VALUE';

export const GET_TRANSACTIONS = 'transactions/GET_TRANSACTION';

declare interface TransactionState {
  itemsList: Paginated<Transaction>;
  item: Transaction;
}

const initialState: TransactionState = {
  itemsList: {
    total: 0,
    data: [],
    current_page: 1
  },
  item: {
    amount: '$0.00',
    fee: '$0.00',
    note: '',
    payment_id: '',
    token: '',
    recipient_id: 0,
    sender_id: 0,
    status: 'pending',

    recipient: {
      name: '',
      email: '',
      role_id: 2, // regular user
      profile: {},
      profit: 0,
      rank: 0,
      is_confirmed: false,
      image_id: null,
      balance: 0,
      image: null,
      pay_pal_account: null,
      referral_code: '',
      referrals: [],
      trophies: []
    },
    sender: {
      name: '',
      email: '',
      role_id: 2, // regular user
      profile: {},
      profit: 0,
      rank: 0,
      is_confirmed: false,
      image_id: null,
      balance: 0,
      image: null,
      pay_pal_account: null,
      referral_code: '',
      referrals: [],
      trophies: []
    }
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_TRANSACTIONS](state: TransactionState) {
      return state.itemsList;
    }
  },

  mutations: {
    [SET_TRANSACTIONS](state: TransactionState, transactions: Paginated<Transaction>) {
      state.itemsList = transactions;
    },

    [SET_TRANSACTION_ITEM](state: TransactionState, transaction: Transaction) {
      state.item = transaction;
    },

    [SET_TRANSACTION_VALUE](state: TransactionState, data: { id: number, transaction: Transaction }) {
      const itemIndex = findIndex(state.itemsList.data, { id: data.id });

      Vue.set(state.itemsList.data, itemIndex, data.transaction);
    },

    [RESET_STATE](state: TransactionState) {
      state.itemsList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        amount: '$0.00',
        fee: '$0.00',
        note: '',
        payment_id: '',
        token: '',
        recipient_id: 0,
        sender_id: 0,
        status: 'pending',

        recipient: {
          name: '',
          email: '',
          role_id: 2, // regular user
          profile: {},
          profit: 0,
          rank: 0,
          is_confirmed: false,
          image_id: null,
          balance: 0,
          image: null,
          pay_pal_account: null,
          referral_code: '',
          referrals: [],
          trophies: []
        },
        sender: {
          name: '',
          email: '',
          role_id: 2, // regular user
          profile: {},
          profit: 0,
          rank: 0,
          is_confirmed: false,
          image_id: null,
          balance: 0,
          image: null,
          pay_pal_account: null,
          referral_code: '',
          referrals: [],
          trophies: []
        }
      };
    }
  },

  actions: {
    [SEARCH_TRANSACTIONS]({ commit }: { commit: Commit }, filters: SearchTransactionsParams) {
      const params = Object.assign({}, filters, {
        with: ['sender.pay_pal_account', 'recipient.pay_pal_account']
      });

      return axios.get('/transactions', { params: pickBy(params) })
        .then((response) => commit(SET_TRANSACTIONS, response.data));
    },

    [FETCH_TRANSACTION]({ commit }: { commit: Commit }, id: number) {
      return axios.get(`/transactions/${id}`)
        .then((response) => commit(SET_TRANSACTION_ITEM, response.data));
    },

    [CONFIRM_TRANSACTION]({ commit, state }: { commit: Commit, state: TransactionState }) {
      return axios.post(`/withdraw/${state.item.id}`, { decision: 'accept' })
        .then((response) => commit(SET_TRANSACTION_ITEM, response.data));
    },

    [DECLINE_TRANSACTION]({ commit, state }: { commit: Commit, state: TransactionState }) {
      return axios.post(`/withdraw/${state.item.id}`, { decision: 'decline' })
        .then((response) => commit(SET_TRANSACTION_ITEM, response.data));
    }
  }
};
