import { Commit, Dispatch } from 'vuex';
import {
  Competition,
  CompetitionBetType,
  CompetitionUser,
  League,
  NotStrictCompetition,
  Sport
} from '@/types/entities';
import { Paginated, SearchCompetitionsParams, SearchCompetitionUsersParams } from '@/types/search';
import { clone, pickBy, flattenDeep } from 'lodash';
import axios from 'axios';
import { CompetitionAccessType } from '@/types/enums';

export const FETCH_COMPETITIONS = 'competitions/FETCH_COMPETITIONS';
export const FETCH_COMPETITION_ITEM = 'competitions/FETCH_COMPETITION_ITEM';
export const SEARCH_USERS = 'competitions/SEARCH_USERS';
export const CREATE_COMPETITION = 'competitions/CREATE_COMPETITION';
export const UPDATE_COMPETITION = 'competitions/UPDATE_COMPETITION';
export const DELETE_COMPETITION = 'competitions/DELETE_COMPETITION';
export const DEACTIVATE_COMPETITION = 'competitions/DEACTIVATE_COMPETITION';
export const ACTIVATE_COMPETITION = 'competitions/ACTIVATE_COMPETITION';

export const RESET_STATE = 'competitions/RESET_STATE';
export const SET_COMPETITIONS = 'competitions/SET_COMPETITIONS';
export const SET_COMPETITION_ITEM = 'competitions/SET_COMPETITION_ITEM';
export const SET_COMPETITION_ITEM_VALUE = 'competitions/SET_COMPETITION_ITEM_VALUE';
export const SET_COMPETITION_USERS = 'competitions/SET_COMPETITION_USERS';

export const GET_COMPETITIONS = 'competitions/GET_COMPETITION';

declare interface CompetitionState {
  competitionsList: Paginated<Competition>;
  competitionUsersList: Paginated<CompetitionUser>;
  item: Competition;
}

const initialState: CompetitionState = {
  competitionsList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  },
  competitionUsersList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 10
  },
  item: {
    name: '',
    slug: '',
    access: CompetitionAccessType.PUBLIC,
    bookmaker_id: null,
    description: '',
    short_description: '',
    prizes_description: '',
    terms: '',
    type: 'profitability',
    is_prizes_paid: false,
    from: null,
    to: null,
    prizes: [],
    image_id: null,

    sports: [],
    leagues: [],
    bet_types: [],
    competition_users: [],
    leaders: []
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_COMPETITIONS](state: CompetitionState) {
      return state.competitionsList;
    }
  },

  mutations: {
    [SET_COMPETITIONS](state: CompetitionState, competitions: Paginated<Competition>) {
      state.competitionsList = competitions;
    },
    [SET_COMPETITION_ITEM](state: CompetitionState, competition: Competition) {
      if (!competition.competition_users) {
        competition.competition_users = [];
      }

      state.item = competition;
    },
    [SET_COMPETITION_USERS](state: CompetitionState, competitionUsersList: Paginated<CompetitionUser>) {
      state.competitionUsersList = competitionUsersList;
    },
    [SET_COMPETITION_ITEM_VALUE](state: CompetitionState, data: NotStrictCompetition) {
      Object.assign(state.item, data);
    },
    [RESET_STATE](state: CompetitionState) {
      state.competitionsList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.competitionUsersList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        name: '',
        slug: '',
        access: CompetitionAccessType.PUBLIC,
        bookmaker_id: null,
        description: '',
        short_description: '',
        prizes_description: '',
        terms: '',
        type: 'profitability',
        is_prizes_paid: false,
        from: null,
        to: null,
        prizes: [],
        image_id: null,

        sports: [],
        leagues: [],
        bet_types: [],
        competition_users: [],
        leaders: []
      };
    }
  },

  actions: {
    [FETCH_COMPETITIONS]({ commit }: { commit: Commit }, params: SearchCompetitionsParams) {
      return axios.get('/competitions', { params: pickBy(params) })
        .then((response) => commit(SET_COMPETITIONS, response.data));
    },

    [FETCH_COMPETITION_ITEM]({ commit }: { commit: Commit }, slug: string) {
      const params = {
        with: ['image', 'sports', 'sports.supported_bet_types', 'leagues', 'bet_types', 'leaders', 'bookmaker'],
        with_trashed: 1
      };

      return axios.get(`/competitions/${slug}`, { params })
        .then((response) => commit(SET_COMPETITION_ITEM, response.data));
    },

    [CREATE_COMPETITION]({ commit, state }: { commit: Commit, state: CompetitionState }) {
      const data: Competition = clone(state.item);

      data.sports = (data.sports as Sport[]).map((sport: Sport) => sport.id as number);
      data.leagues = flattenDeep((data.leagues as League[]).map((leagues: League) => leagues.id as number));
      data.bet_types = (data.bet_types as CompetitionBetType[])
        .map((betType: CompetitionBetType) => betType.bet_type as string);

      return axios.post('/competitions', data);
    },

    [UPDATE_COMPETITION]({ commit, state }: { commit: Commit, state: CompetitionState }) {
      const data: Competition = clone(state.item);

      data.sports = (data.sports as Sport[]).map((sport: Sport) => sport.id as number);
      data.leagues = (data.leagues as League[]).map((leagues: League) => leagues.id as number);
      data.bet_types = (data.bet_types as CompetitionBetType[])
        .map((betType: CompetitionBetType) => betType.bet_type as string);

      return axios.put(`/competitions/${state.item.id}`, data);
    },

    [DELETE_COMPETITION](
      { state, dispatch }: { state: CompetitionState, dispatch: Dispatch },
      { competitionId, searchParams }: { competitionId: string, searchParams: SearchCompetitionsParams }
    ) {
      return axios.delete(`/competitions/${competitionId || state.item.id}?force=1`)
        .then(() => dispatch(FETCH_COMPETITIONS, searchParams));
    },

    [DEACTIVATE_COMPETITION]({ commit, state }: { commit: Commit, state: CompetitionState }) {
      return axios.delete(`/competitions/${state.item.id}`);
    },

    [ACTIVATE_COMPETITION]({ commit, state }: { commit: Commit, state: CompetitionState }) {
      return axios.post(`/competitions/${state.item.id}/restore`);
    },

    [SEARCH_USERS](
        { commit, state }: { commit: Commit, state: CompetitionState },
        params: SearchCompetitionUsersParams
    ) {
      return axios.get(`/competitions/${state.item.id}/users`, { params: pickBy(params) })
          .then((response) => commit(SET_COMPETITION_USERS, response.data));
    }
  }
};
