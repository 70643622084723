import { Commit } from 'vuex';
import { Tag } from '@/types/entities';
import axios from 'axios';

export const FETCH_TAGS = 'tags/FETCH_TAGS';
export const SET_TAGS = 'tags/SET_TAGS';
export const GET_TAGS = 'tags/GET_TAGS';

declare interface TagStore {
  tagsList: Tag[];
}

const initialState: TagStore = {
  tagsList: []
};

export default {
  state: { ...initialState },
  getters: {
    [GET_TAGS](state: any) {
      return state.tagsList;
    }
  },
  mutations: {
    [SET_TAGS](state: any, tags: Tag[]) {
      state.tagsList = tags;
    }
  },
  actions: {
    [FETCH_TAGS]({ commit }: { commit: Commit }) {
      return axios.get('/tags', { params: { all: 1 } })
        .then((response) => commit(SET_TAGS, response.data.data));
    }
  }
};
