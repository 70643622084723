import { Commit } from 'vuex';
import { BookmakerPromotion, NotStrictBookmakerPromotion } from '@/types/entities';
import { Paginated, SearchBookmakerPromotionsParams } from '@/types/search';
import { clone, pickBy } from 'lodash';
import axios from 'axios';

export const FETCH_BOOKMAKER_PROMOTIONS = 'bookmakerPromotions/FETCH_BOOKMAKER_PROMOTIONS';
export const FETCH_BOOKMAKER_PROMOTIONS_ITEM = 'bookmakerPromotions/FETCH_BOOKMAKER_PROMOTIONS_ITEM';
export const CREATE_BOOKMAKER_PROMOTION = 'bookmakerPromotions/CREATE_BOOKMAKER_PROMOTION';
export const UPDATE_BOOKMAKER_PROMOTION = 'bookmakerPromotions/UPDATE_BOOKMAKER_PROMOTION';
export const DELETE_BOOKMAKER_PROMOTION = 'bookmakerPromotions/DELETE_BOOKMAKER_PROMOTION';

export const RESET_STATE = 'bookmakerPromotions/RESET_STATE';
export const SET_BOOKMAKER_PROMOTIONS = 'bookmakerPromotions/SET_BOOKMAKER_PROMOTIONS';
export const SET_BOOKMAKER_PROMOTIONS_ITEM = 'bookmakerPromotions/SET_BOOKMAKER_PROMOTIONS_ITEM';
export const SET_BOOKMAKER_PROMOTIONS_ITEM_VALUE = 'bookmakerPromotions/SET_BOOKMAKER_PROMOTIONS_ITEM_VALUE';

export const GET_BOOKMAKER_PROMOTIONS = 'bookmakerPromotions/GET_BOOKMAKER_PROMOTIONS';

declare interface BookmakerPromotionsState {
  bookmakerPromotionList: Paginated<BookmakerPromotion>;
  item: BookmakerPromotion;
}

const initialState: BookmakerPromotionsState = {
  bookmakerPromotionList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  },
  item: {
    bookmaker_id: null,
    sport_id: null,
    offer_description: '',
    expiry_at: ''
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_BOOKMAKER_PROMOTIONS](state: BookmakerPromotionsState) {
      return state.bookmakerPromotionList;
    }
  },

  mutations: {
    [SET_BOOKMAKER_PROMOTIONS](state: BookmakerPromotionsState, promotions: Paginated<BookmakerPromotion>) {
      state.bookmakerPromotionList = promotions;
    },
    [SET_BOOKMAKER_PROMOTIONS_ITEM](state: BookmakerPromotionsState, promotion: BookmakerPromotion) {
      state.item = promotion;
    },
    [SET_BOOKMAKER_PROMOTIONS_ITEM_VALUE](state: BookmakerPromotionsState, data: NotStrictBookmakerPromotion) {
      Object.assign(state.item, data);
    },
    [RESET_STATE](state: BookmakerPromotionsState) {
      state.bookmakerPromotionList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        bookmaker_id: null,
        sport_id: null,
        offer_description: '',
        expiry_at: ''
      };
    }
  },

  actions: {
    [FETCH_BOOKMAKER_PROMOTIONS]({ commit }: { commit: Commit }, params: SearchBookmakerPromotionsParams) {
      return axios.get('/bookmaker-promotions', { params: pickBy(params) })
        .then((response) => commit(SET_BOOKMAKER_PROMOTIONS, response.data));
    },
    [FETCH_BOOKMAKER_PROMOTIONS_ITEM]({ commit }: { commit: Commit }, slug: string) {
      const params = {
        with: ['sport', 'bookmaker']
      };

      return axios.get(`/bookmaker-promotions/${slug}`, { params })
        .then((response) => commit(SET_BOOKMAKER_PROMOTIONS_ITEM, response.data));
    },
    [CREATE_BOOKMAKER_PROMOTION]({ commit, state }: { commit: Commit, state: BookmakerPromotionsState }) {
      const data: BookmakerPromotion = clone(state.item);

      return axios.post('/bookmaker-promotions', data);
    },
    [UPDATE_BOOKMAKER_PROMOTION]({ commit, state }: { commit: Commit, state: BookmakerPromotionsState }) {
      const data: BookmakerPromotion = clone(state.item);

      return axios.put(`/bookmaker-promotions/${state.item.id}`, data);
    },
    [DELETE_BOOKMAKER_PROMOTION]({ commit, state }: { commit: Commit, state: BookmakerPromotionsState }) {
      return axios.delete(`/bookmaker-promotions/${state.item.id}`);
    }
  }
};
