import { State } from '@/types/entities';
import axios from 'axios';
import { Commit } from 'vuex';

export const FETCH_STATES = 'states/FETCH_STATES';
export const GET_STATES = 'states/GET_STATES';
export const SET_STATES = 'states/SET_STATES';

declare interface StatesStore {
  statesList: State[];
}

const initialState: StatesStore = {
  statesList: []
};

export default {
  state: { ...initialState },
  getters: {
    [GET_STATES](state: any) {
      return state.states;
    }
  },
  mutations: {
    [SET_STATES](state: any, states: State[]) {
      state.statesList = states;
    }
  },
  actions: {
    [FETCH_STATES]({ commit }: { commit: Commit }) {
      return axios.get('/states', { params: { all: 1 } })
        .then((response) => commit(SET_STATES, response.data.data));
    }
  }
};
