import VueAuth from '@d0whc3r/vue-auth-plugin';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBaseballBall,
  faBook,
  faBookmark,
  faCheck,
  faCheckSquare,
  faCogs, faComments, faHandHoldingUsd,
  faNewspaper, faPercentage, faPlusCircle,
  faRobot,
  faSignOutAlt, faSitemap, faSquare,
  faTrophy,
  faUser,
  faUserTie, faVolleyballBall
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import Croppa from 'vue-croppa';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'https://dev.api.oddscrowd.com/';
axios.defaults.headers = {
  ...axios.defaults.headers,
  authorization: 'Bearer {auth_token}',
};

Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VModal);
Vue.use(Croppa);
Vue.use(datePicker);

library.add(faBaseballBall);
library.add(faBook);
library.add(faBookmark);
library.add(faCheck);
library.add(faCheckSquare);
library.add(faCogs);
library.add(faVolleyballBall);
library.add(faHandHoldingUsd);
library.add(faNewspaper);
library.add(faPlusCircle);
library.add(faRobot);
library.add(faSignOutAlt);
library.add(faSquare);
library.add(faTrophy);
library.add(faUser);
library.add(faUserTie);
library.add(faComments);
library.add(faSitemap);
library.add(faPercentage);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.config.productionTip = false;
Vue.router = router;

Vue.use(VueAuth, {
  ...VueAuth.DEFAULT_OPTIONS,
  tokenStore: ['localStorage'],
  fetchData: {
    url: '/profile',
    method: 'GET',
    interval: 30,
    enabled: true,
  }
});

let isRefreshing = false;

axios.interceptors.request.use((value: AxiosRequestConfig): AxiosRequestConfig => {
  store.commit('incrementRequestsCount');

  return value;
});

axios.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
  store.commit('decrementRequestsCount');

  return value;
}, (value: AxiosResponse): AxiosResponse => {
  store.commit('decrementRequestsCount');

  return value;
});

axios.interceptors.request.use(async (value: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = localStorage.getItem('default_auth_token');

  if ((typeof token === 'undefined') || (token === null) || !token.length) {
    return value;
  }

  const tokenExpiresAt = (jwtDecode(token) as any).exp * 1000;

  if (isRefreshing || moment.utc().isSameOrBefore(tokenExpiresAt)) {
    value.headers.authorization = `Bearer ${token}`;

    return value;
  }

  isRefreshing = true;

  try {
    const response = await axios.request({
      url: 'auth/refresh',
      method: 'get',
      headers: {Authorization: token}
    });

    await localStorage.setItem('default_auth_token', response.headers.authorization);

    axios.defaults.headers.authorization = `Bearer ${response.headers.authorization}`;

    value.headers.authorization = response.headers.authorization;
  } catch (error: any) {
    if ([400, 401].includes(error.response.statusCode)) {
      router.push('/login');

      return Promise.reject();
    }

    throw error;
  } finally {
    isRefreshing = false;
  }

  return value;
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
