import { Commit } from 'vuex';
import { Insight, NotStrictInsight, Tag } from '@/types/entities';
import { Paginated, SearchInsightsParams } from '@/types/search';
import { clone, pickBy } from 'lodash';
import axios from 'axios';

export const FETCH_INSIGHTS = 'insights/FETCH_INSIGHTS';
export const FETCH_INSIGHT_ITEM = 'insights/FETCH_INSIGHT_ITEM';
export const CREATE_INSIGHT = 'insights/CREATE_INSIGHT';
export const UPDATE_INSIGHT = 'insights/UPDATE_INSIGHT';
export const DELETE_INSIGHT = 'insights/DELETE_INSIGHT';

export const RESET_STATE = 'insights/RESET_STATE';
export const ADD_TAG = 'insights/ADD_TAG';
export const REMOVE_TAG = 'insights/REMOVE_TAG';
export const SET_INSIGHTS = 'insights/SET_INSIGHTS';
export const SET_INSIGHT_ITEM = 'insights/SET_INSIGHT_ITEM';
export const SET_INSIGHT_ITEM_VALUE = 'insights/SET_INSIGHT_ITEM_VALUE';

export const GET_INSIGHTS = 'insights/GET_INSIGHT';

declare interface InsightState {
  insightsList: Paginated<Insight>;
  item: Insight;
}

const initialState: InsightState = {
  insightsList: {
    total: 0,
    data: [],
    current_page: 1
  },
  item: {
    title: '',
    slug: '',
    meta_title: '',
    content: '',
    meta_description: '',
    media: {
      link: ''
    },
    tags: []
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_INSIGHTS](state: InsightState) {
      return state.insightsList;
    }
  },

  mutations: {
    [ADD_TAG](state: InsightState, tag: string) {
      (state.item.tags as Tag[]).push({
        name: tag
      });
    },
    [REMOVE_TAG](state: InsightState, tag: string) {
      state.item.tags = (state.item.tags as Tag[])
        .filter((itemTag: Tag) => itemTag.name !== tag);
    },
    [SET_INSIGHTS](state: InsightState, insights: Paginated<Insight>) {
      state.insightsList = insights;
    },
    [SET_INSIGHT_ITEM](state: InsightState, insight: Insight) {
      state.item = insight;
    },
    [SET_INSIGHT_ITEM_VALUE](state: InsightState, data: NotStrictInsight) {
      Object.assign(state.item, data);
    },
    [RESET_STATE](state: InsightState) {
      state.insightsList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        title: '',
        slug: '',
        meta_title: '',
        content: '',
        meta_description: '',
        media: {
          link: ''
        },
        tags: []
      };
    }
  },

  actions: {
    [FETCH_INSIGHTS]({ commit }: { commit: Commit }, params: SearchInsightsParams) {
      return axios.get('/insights', { params: pickBy(params) })
        .then((response) => commit(SET_INSIGHTS, response.data));
    },
    [FETCH_INSIGHT_ITEM]({ commit }: { commit: Commit }, slug: string) {
      const params = {
        with: ['media', 'tags']
      };

      return axios.get(`/insights/${slug}`, { params })
        .then((response) => commit(SET_INSIGHT_ITEM, response.data));
    },
    [CREATE_INSIGHT]({ commit, state }: { commit: Commit, state: InsightState }) {
      const data: Insight = clone(state.item);

      data.tags = (state.item.tags as Tag[]).map((tag: Tag) => tag.name);

      return axios.post('/insights', data);
    },
    [UPDATE_INSIGHT]({ commit, state }: { commit: Commit, state: InsightState }) {
      const data: Insight = clone(state.item);

      data.tags = (state.item.tags as Tag[]).map((tag: Tag) => tag.name);

      return axios.put(`/insights/${state.item.id}`, data);
    },
    [DELETE_INSIGHT]({ commit, state }: { commit: Commit, state: InsightState }) {
      return axios.delete(`/insights/${state.item.id}`);
    }
  }
};
