export enum roles {
  admin = 1,
  user,
  bot
}

export enum strategies {
  monkey = 'monkey',
  crowdFollower = 'crowd_follower',
  favouriteLover = 'favourite_lover',
  leaderFollower = 'leader_follower',
  underdogLover = 'underdog_lover'
}

export enum betTypes {
  moneyline = 'HA_OT',
  spread = 'AH_OT',
  total = 'OU_OT'
}

export enum BetGroup {
  MONEYLINE = 'HA',
  SPREAD = 'AH',
  TOTAL = 'OU',
  DRAW_RETURNED = 'DNB'
}

export enum CompetitionAccessType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  LOCKED = 'locked'
}

export enum TrophyRank {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3
}

export enum SettingMultiSelectMode {
  DEFAULT_SPORTS = 'default_sports'
}
