import Vue from 'vue';
import Vuex from 'vuex';
import betTypes from './betTypes';
import bookmakerPromotions from './bookmakerPromotions';
import bookmakers from './bookmakers';
import competitions from './competitions';
import insights from './insights';
import leagues from './leagues';
import messages from './messages';
import picks from './picks';
import referralCodes from './referralCodes';
import settings from './settings';
import sports from './sports';
import states from './states';
import tags from './tags';
import transactions from './transactions';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: '',
    pendingRequestsCount: 0
  },
  mutations: {
    setTitle(state, value) {
      state.title = value;
    },
    incrementRequestsCount(state) {
      state.pendingRequestsCount++;
    },
    decrementRequestsCount(state) {
      state.pendingRequestsCount--;
    }
  },
  modules: {
    insights,
    tags,
    bookmakers,
    competitions,
    betTypes,
    leagues,
    picks,
    settings,
    sports,
    transactions,
    users,
    messages,
    referralCodes,
    bookmakerPromotions,
    states
  }
});
