import { Commit } from 'vuex';
import { ReferralCode } from '@/types/entities';
import { Paginated, SearchReferralCodesParams } from '@/types/search';
import { pickBy, cloneDeep } from 'lodash';
import axios from 'axios';

export const FETCH_REFERRAL_CODES = 'messages/REFERRAL_CODES';
export const FETCH_REFERRAL_CODE = 'messages/REFERRAL_CODE';
export const DELETE_REFERRAL_CODE = 'messages/DELETE_REFERRAL_CODE';
export const CREATE_OR_UPDATE_REFERRAL_CODE = 'messages/CREATE_OR_UPDATE_REFERRAL_CODE';

export const SET_REFERRAL_CODES = 'messages/SET_REFERRAL_CODES';
export const RESET_REFERRAL_CODE_ITEM = 'messages/RESET_REFERRAL_CODE_ITEM';
export const SET_REFERRAL_CODE_ITEM = 'messages/SET_REFERRAL_CODE_ITEM';
export const RESET_STATE = 'messages/RESET_STATE';
export const SET_REFERRAL_CODE_ITEM_VALUE = 'bookmakers/SET_REFERRAL_CODE_ITEM_VALUE';

export const GET_REFERRAL_CODES = 'messages/GET_REFERRAL_CODES';

declare interface ReferralCodesState {
  referralCodesList: Paginated<ReferralCode>;
  item: ReferralCode;
}

const initialState: ReferralCodesState = {
  referralCodesList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  },
  item: {
    description: '',
    code: ''
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_REFERRAL_CODES](state: ReferralCodesState) {
      return state.referralCodesList;
    }
  },

  mutations: {
    [SET_REFERRAL_CODES](state: ReferralCodesState, referralCodes: Paginated<ReferralCode>) {
      state.referralCodesList = referralCodes;
    },
    [RESET_STATE](state: ReferralCodesState) {
      state = { ...initialState };
    },
    [RESET_REFERRAL_CODE_ITEM](state: ReferralCodesState) {
      state.item = { ...initialState.item };
    },
    [SET_REFERRAL_CODE_ITEM](state: ReferralCodesState, referralCode: ReferralCode) {
      state.item = { ...referralCode };
    },
    [SET_REFERRAL_CODE_ITEM_VALUE](state: ReferralCodesState, data: ReferralCode) {
      Object.assign(state.item, data);
    },
  },

  actions: {
    [FETCH_REFERRAL_CODES]({ commit }: { commit: Commit }, params: SearchReferralCodesParams) {
      return axios.get('/partner-referral-codes', { params: pickBy(params) })
        .then((response) => commit(SET_REFERRAL_CODES, response.data));
    },
    [FETCH_REFERRAL_CODE]({ commit }: { commit: Commit }, id: number) {
      return axios.get(`/partner-referral-codes/${id}`)
        .then((response) => commit(SET_REFERRAL_CODE_ITEM, response.data));
    },
    [DELETE_REFERRAL_CODE]({}, referralCodeId: number) {
      return axios.delete(`/partner-referral-codes/${referralCodeId}`);
    },
    [CREATE_OR_UPDATE_REFERRAL_CODE]({ state }: { state: ReferralCodesState }) {
      const data: Partial<ReferralCode> = pickBy(cloneDeep(state.item));

      return (data.id)
        ? axios.put(`/partner-referral-codes/${data.id}`, data)
        : axios.post('/partner-referral-codes', data);
    }
  }
};
