import { Bookmaker, NotStrictBookmaker } from '@/types/entities';
import { Paginated, SearchBookmakersParams } from '@/types/search';
import axios from 'axios';
import { clone, omit, pickBy } from 'lodash';
import { Commit } from 'vuex';

export const FETCH_BOOKMAKERS = 'bookmakers/FETCH_BOOKMAKERS';
export const FETCH_BOOKMAKER_ITEM = 'bookmakers/FETCH_BOOKMAKER_ITEM';
export const CREATE_BOOKMAKER = 'bookmakers/CREATE_BOOKMAKER';
export const UPDATE_BOOKMAKER = 'bookmakers/UPDATE_BOOKMAKER';
export const DELETE_BOOKMAKER = 'bookmakers/DELETE_BOOKMAKER';

export const RESET_STATE = 'bookmakers/RESET_STATE';
export const SET_BOOKMAKERS = 'bookmakers/SET_BOOKMAKERS';
export const SET_BOOKMAKER_ITEM = 'bookmakers/SET_BOOKMAKER_ITEM';
export const SET_BOOKMAKER_ITEM_VALUE = 'bookmakers/SET_BOOKMAKER_ITEM_VALUE';

export const GET_BOOKMAKERS = 'bookmakers/GET_BOOKMAKER';

declare interface BookmakerState {
  bookmakersList: Paginated<Bookmaker>;
  item: Bookmaker;
}

const initialState: BookmakerState = {
  bookmakersList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  },
  item: {
    name: '',
    slug: '',
    guest_clicks_count: 0,
    user_clicks_count: 0,
    is_supported: 0,
    rating: 1,
    advantages: '',
    deposit: 0,
    bonus: 0,
    bet_with: 0,
    signup_bonus: '',
    key_points: '',
    review: '',
    referral_link: '',
    is_public: 1,
    image_id: null,
    image: {
      link: ''
    },
    states: []
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_BOOKMAKERS](state: BookmakerState) {
      return state.bookmakersList;
    }
  },

  mutations: {
    [SET_BOOKMAKERS](state: BookmakerState, bookmakers: Paginated<Bookmaker>) {
      state.bookmakersList = bookmakers;
    },
    [SET_BOOKMAKER_ITEM](state: BookmakerState, bookmaker: Bookmaker) {
      state.item = bookmaker;
    },
    [SET_BOOKMAKER_ITEM_VALUE](state: BookmakerState, data: NotStrictBookmaker) {
      Object.assign(state.item, data);
    },
    [RESET_STATE](state: BookmakerState) {
      state.bookmakersList = {
        total: 0,
        data: [],
        current_page: 1
      };

      state.item = {
        name: '',
        slug: '',
        guest_clicks_count: 0,
        user_clicks_count: 0,
        is_supported: 0,
        rating: 1,
        advantages: '',
        deposit: 0,
        bonus: 0,
        bet_with: 0,
        signup_bonus: '',
        key_points: '',
        review: '',
        referral_link: '',
        is_public: 1,
        image_id: null,
        image: {
          link: ''
        },
        states: []
      };
    }
  },

  actions: {
    [FETCH_BOOKMAKERS]({ commit }: { commit: Commit }, params: SearchBookmakersParams) {
      return axios.get('/bookmakers', { params: pickBy(params) })
        .then((response) => commit(SET_BOOKMAKERS, response.data));
    },
    [FETCH_BOOKMAKER_ITEM]({ commit }: { commit: Commit }, slug: string) {
      const params = {
        with: ['image', 'states']
      };

      return axios.get(`/bookmakers/${slug}`, { params })
        .then((response) => commit(SET_BOOKMAKER_ITEM, response.data));
    },
    [CREATE_BOOKMAKER]({ commit, state }: { commit: Commit, state: BookmakerState }) {
      const data: Bookmaker = clone({
        ...state.item,
        state_ids: state.item.states.map((state) => state.id)
      });

      return axios.post('/bookmakers', omit(data, ['states']));
    },
    [UPDATE_BOOKMAKER]({ commit, state }: { commit: Commit, state: BookmakerState }) {
      const data: Bookmaker = clone({
        ...state.item,
        state_ids: state.item.states.map((state) => state.id)
      });

      return axios.put(`/bookmakers/${state.item.id}`, omit(data, ['states']));
    },
    [DELETE_BOOKMAKER]({ commit, state }: { commit: Commit, state: BookmakerState }) {
      return axios.delete(`/bookmakers/${state.item.id}`);
    }
  }
};
