import { BetType } from '@/types/entities';
import { Paginated } from '@/types/search';

export const RESET_STATE = 'bet_types/RESET_STATE';
export const SET_BET_TYPES = 'bet_types/SET_BET_TYPES';

export const GET_BET_TYPES = 'bet_types/GET_BET_TYPE';

declare interface BetTypeState {
  betTypesList: Paginated<BetType>;
}

const initialState: BetTypeState = {
  betTypesList: {
    total: 0,
    data: [],
    current_page: 1,
    per_page: 20
  }
};

export default {
  state: { ...initialState },

  getters: {
    [GET_BET_TYPES](state: BetTypeState) {
      return state.betTypesList;
    }
  },

  mutations: {
    [SET_BET_TYPES](state: BetTypeState, betTypes: Paginated<BetType>) {
      state.betTypesList = betTypes;
    },
    [RESET_STATE](state: BetTypeState) {
      state.betTypesList = {
        total: 0,
        data: [],
        current_page: 1
      };
    }
  }
};
